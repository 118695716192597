import React from 'react'
import Vars from 'Consts/vars'
import { moment_date, truncate_text } from 'Helpers/utils'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'

const TypeFive = (props) => (
  <div className={`fdn-article-card-desktop-${props.type}`}>
    <div className="article-content">
      <a href={props.article.url} className="article-image">
        <div className="article-image-source">
          <ImageFallback
            src={props.article.image}
            alt={props.article.title}
            objectFit="cover"
            layout="fill"
            quality={80}
            priority
            fallbackSrc={Vars.imageBrokenDefault.rectangle}
          />
        </div>
      </a>
      <div className="article-title">
        <p className="article-category">
          <a href={props.url_category} className="text-category">
            {props.article.category.name}
          </a>
          {props.article.date && (
            <span className="article-text-date">
              {moment_date({ date: props.article.date })}
            </span>
          )}
        </p>
        <a className="article-url-link-to" href={props.article.url}>
          {props.article.title}
        </a>
        {props.article.text.length > 140 ? (
          <p className="article-text" href={props.article.url}>
            {truncate_text(props.article.text, 140)}
          </p>
        ) : (
          <p className="article-text" href={props.article.url}>
            {props.article.text}
          </p>
        )}
        {props.article.author.fullname && (
          <p className="article-author">
            by{' '}
            <a className="article-author-italic" href={props.url_author}>
              {props.article.author.fullname}
            </a>
          </p>
        )}
      </div>
    </div>
    <style jsx>
      {`
        .fdn-article-card-desktop-${props.type} {
          display: flex;
          flex-flow: column;
          border: none;
          word-break: break-word;
          border-bottom: ${props.border_bottom ? `1px solid #DFE3E8` : ``};
          padding-bottom: ${props.border_bottom ? `25px` : `20`};
          margin-bottom: 20px;
        }
        .fdn-article-card-desktop-${props.type} .article-content {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-auto-rows: minmax(min-content, max-content);
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-image {
          width: 377px;
          height: ${props.height_img_small ? props.height_img_small : `280px`};
          padding: 5px 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-image
          .article-image-source {
          width: 100%;
          height: 100%;
          position: relative;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-image:hover {
          ${Vars.HOVER_TYPE.IMAGE}
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title {
          padding-left: 30px;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          p.article-category {
          font-family: Roboto, sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 1;
          color: #db284e;
          text-transform: uppercase;
          margin: 5px 0 10px 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          p.article-category
          .text-category {
          text-decoration: none;
          color: #db284e;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          p.article-category
          .text-category:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
          cursor: pointer;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          p.article-category
          span.article-text-date {
          color: #919eab;
          position: relative;
          margin-left: 85px;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          p.article-category
          span.article-text-date::before {
          position: absolute;
          content: '';
          width: 40px;
          height: 1px;
          background: #919eab;
          top: 50%;
          left: -60px;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .article-url-link-to {
          font-family: ${props.font_title};
          font-weight: 600;
          font-size: 20px;
          color: #000;
          line-height: 1.5;
          margin: 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .article-url-link-to:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .article-author {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 400;
          color: #919eab;
          margin: 10px 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .article-author
          .article-author-italic {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 700;
          font-style: italic;
          text-transform: capitalize;
          margin-left: 3px;
          color: #919eab;
          text-decoration: none;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .article-author
          .article-author-italic:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
          cursor: pointer;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .article-text {
          font-family: Roboto;
          font-size: 14px;
          line-height: 1.56;
          letter-spacing: 0.5px;
          font-weight: 400;
          color: #454f5b;
          margin: 10px 0 0 0;
        }
      `}
    </style>
  </div>
)

export default TypeFive
