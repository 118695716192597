import React from 'react'
import Mobile from './mobile'
import Desktop from './desktop'

/**
 *
 * @param {Boolean} props.is_mobile
 * @param {String, Number} props.numbering
 * @param {Boolean} props.is_sponsored
 * @param {String} props.font_title
 * @param {OBject} props.article,
 * @param {String} props.height_img_big,
 * @param {String} props.height_img_small,
 * @param {string} props.type,
 * @param {Boolean} props.border_bottom,
 * @param {String} props.size,
 */
const ArticleCardHome = ({
  is_mobile,
  numbering,
  is_sponsored,
  font_title,
  article,
  height_img_big,
  height_img_small,
  type,
  border_bottom,
  size,
  url_category,
  url_author,
}) => {
  const props = {
    numbering: numbering || 1,
    is_mobile: is_mobile || false,
    is_sponsored: is_sponsored || false,
    font_title: font_title || `Poppins, sans-serif`,
    article: article || {},
    height_img_big: height_img_big,
    height_img_small: height_img_small,
    type: type || 'type-1',
    border_bottom: border_bottom || false,
    size: size,
    url_category: url_category,
    url_author: url_author,
  }

  if (props.is_mobile) {
    return <Mobile {...props} />
  } else {
    return <Desktop {...props} />
  }
}

export default ArticleCardHome
