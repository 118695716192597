import React from 'react'
import Vars from 'Consts/vars'
import { truncate_text } from 'Helpers/utils'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'

const TypeSix = (props) => (
  <div
    className={`fdn-article-card-desktop-${props.type} ${
      props.size || 'small-article'
    }`}
  >
    <a href={props.article.url} className="article-image">
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ImageFallback
          src={props.article.image}
          alt={props.article.title}
          objectFit="cover"
          layout="fill"
          priority
          quality={80}
          fallbackSrc={Vars.imageBrokenDefault.rectangle}
        />
      </div>
      {props.is_sponsored && (
        <p className="article-text-sponsored">Sponsored</p>
      )}
    </a>
    <p className="article-category">
      {truncate_text(props.article.category.name, 26)}
    </p>
    <a className="article-title" href={props.article.url}>
      {props.article.title}
    </a>
    {props.size == 'big' &&
      (props.article.text.length > 140 ? (
        <a className="article-text" href={props.article.url}>
          {truncate_text(props.article.text, 140)}
        </a>
      ) : (
        <a className="article-text" href={props.article.url}>
          {props.article.text}
        </a>
      ))}
    <style jsx>
      {`
        .fdn-article-card-desktop-${props.type} {
          display: flex;
          flex-flow: column;
          border: none;
          word-break: break-word;
        }
        .fdn-article-card-desktop-${props.type} .article-image {
          width: 100%;
          height: ${props.height_img_small ? props.height_img_small : `250px`};
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;
          flex-shrink: 0;
        }
        .fdn-article-card-desktop-${props.type}.big .article-image {
          height: ${props.height_img_big ? props.height_img_big : `538px`};
        }
        .fdn-article-card-desktop-${props.type}.big .article-image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .fdn-article-card-desktop-${props.type}.small-article
          .article-image
          img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .fdn-article-card-desktop-${props.type} .article-image:hover {
          ${Vars.HOVER_TYPE.IMAGE}
        }
        .fdn-article-card-desktop-${props.type}
          .article-image
          .article-text-sponsored {
          position: absolute;
          top: 8px;
          left: 8px;
          margin: 0;
          padding: 4px 8px;
          background-color: #ffffff;
          border-radius: 18px;
          color: #db284e;
          font-family: Roboto;
          font-size: 8px;
          font-style: normal;
          font-weight: 500;
          line-height: 10px;
          letter-spacing: 0px;
          text-align: left;
          text-transform: uppercase;
        }

        .fdn-article-card-desktop-${props.type} .article-title {
          font-family: ${props.font_title};
          font-weight: 600;
          font-size: 20px;
          color: #000;
          line-height: 1.5;
          margin-bottom: 0;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .fdn-article-card-desktop-${props.type} .article-title:hover,
        .fdn-article-card-desktop-${props.type} .article-text:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
        }
        .fdn-article-card-desktop-${props.type} .article-category {
          font-family: Roboto, sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 1;
          color: #db284e;
          text-transform: uppercase;
          margin: 20px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .fdn-article-card-desktop-${props.type} .article-text {
          font-family: Roboto;
          font-size: 16px;
          line-height: 1.56;
          letter-spacing: 0.5px;
          color: #4a4a4a;
          margin: 10px 0 0 0;
        }
      `}
    </style>
  </div>
)

export default TypeSix
