import React from 'react'
import Vars from 'Consts/vars'
import { truncate_text } from 'Helpers/utils'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'

const TypeSix = (props) => (
  <div
    className={`fdn-article-card-mobile-${props.type} ${
      props.size === 'main' ? `main` : ``
    }`}
  >
    <a className="article-image" href={props.article.url}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ImageFallback
          src={props.article.image}
          alt={props.article.title}
          layout="fixed"
          objectFit="cover"
          width={100}
          height={100}
          quality={80}
          fallbackSrc={Vars.imageBrokenDefault.square}
        />
      </div>

      {props.is_sponsored && (
        <p className="article-text-sponsored">Sponsored</p>
      )}
    </a>
    <div className="article-info">
      <p className="article-category">
        {truncate_text(props.article.category.name, 26)}
        {props.is_sponsored && (
          <span className="article-category-sponsored">Sponsored</span>
        )}
      </p>
      <a className="article-title" href={props.article.url}>
        {props.article.title}
      </a>
    </div>
    <style jsx>
      {`
        .fdn-article-card-mobile-${props.type} {
          display: flex;
          flex-flow: row;
          border: none;
          padding: 0 0 10px 0;
        }
        .fdn-article-card-mobile-${props.type}.main {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-flow: column;
          border: none;
        }
        .fdn-article-card-mobile-${props.type} .article-info {
          word-break: break-word;
        }
        .fdn-article-card-mobile-${props.type}.main .article-info {
          word-break: break-word;
          padding: 16px 14px 16px 14px;
          text-align: center;
          backdrop-filter: blur(7px);
          margin-top: -24px;
          width: 95%;
        }

        .fdn-article-card-mobile-${props.type}.main .article-info::before {
          content: '';
          background: #000000;
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          opacity: 0.75;
          z-index: -1;
        }

        .fdn-article-card-mobile-${props.type} .article-image {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-shrink: 0;
          overflow: hidden;
          margin: 0 8px 0 0;
        }
        .fdn-article-card-mobile-${props.type}.main .article-image {
          width: 100%;
          height: 170px;
          margin: 0;
        }
        .fdn-article-card-mobile-${props.type} .article-image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-category {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          color: #db284e;
          margin: 0 0 4px 0;
          text-transform: uppercase;
          padding: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-category
          .article-category-sponsored {
          position: relative;
          margin-left: 35px;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-category
          .article-category-sponsored::before {
          position: absolute;
          content: '';
          width: 15px;
          height: 1px;
          background: #919eab;
          top: 50%;
          left: -25px;
        }
        .fdn-article-card-mobile-${props.type}.main
          .article-info
          p.article-category {
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          color: #ffffff;
          margin: 0 0 4px 0;
          text-transform: uppercase;
          padding: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          z-index: 99;
        }
        .fdn-article-card-mobile-${props.type} .article-info a.article-title {
          font-family: ${props.font_title};
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          color: #000;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .fdn-article-card-mobile-${props.type}.main
          .article-info
          a.article-title {
          font-family: ${props.font_title};
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
          margin: 10px 0 0 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          color: #ffffff;
          z-index: 99;
        }

        .fdn-article-card-mobile-${props.type}
          .article-image
          .article-text-sponsored {
          position: absolute;
          top: 4px;
          left: 4px;
          margin: 0;
          padding: 4px 8px;
          background-color: #ffffff;
          border-radius: 18px;
          color: #db284e;
          font-family: Roboto;
          font-size: 8px;
          font-style: normal;
          font-weight: 500;
          line-height: 10px;
          letter-spacing: 0px;
          text-align: left;
          text-transform: uppercase;
        }
      `}
    </style>
  </div>
)

export default TypeSix
