import React from 'react'
import TypeOne from './type-1'
import TypeTwo from './type-2'
import TypeThree from './type-3'
import TypeFour from './type-4'
import TypeSix from './type-6'
import TypeSeven from './type-7'

// config template
const Template = [
  {
    id: 'type-1',
    component: TypeOne,
  },
  {
    id: 'type-2',
    component: TypeTwo,
  },
  {
    id: 'type-3',
    component: TypeThree,
  },
  {
    id: 'type-4',
    component: TypeFour,
  },
  {
    id: 'type-6',
    component: TypeSix,
  },
  {
    id: 'type-7',
    component: TypeSeven,
  },
]

const Mobile = (props) =>
  Template.filter((item) => item.id === props.type).map((style) => (
    <style.component key={String(style.id)} {...props} />
  ))

export default Mobile
