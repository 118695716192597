import React from 'react'
import Vars from 'Consts/vars'
import { truncate_text, moment_date } from 'Helpers/utils'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'

const TypeTwo = (props) => (
  <div
    className={`fdn-article-card-desktop-${props.type} ${
      props.size || 'small-article'
    }`}
  >
    <a href={props.article.url} className="article-image">
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ImageFallback
          src={props.article.image}
          alt={props.article.title}
          objectFit="cover"
          layout="fill"
          priority
          quality={100}
          fallbackSrc={Vars.imageBrokenDefault.rectangle}
        />
      </div>
    </a>
    <p className="article-category">
      <a href={props.url_category} className="text-category">
        {props.article.category.name}
      </a>
      {props.article.date && (
        <span className="article-text-date">
          {moment_date({ date: props.article.date })}
        </span>
      )}
    </p>
    <a className="article-title" href={props.article.url}>
      {props.article.title}
    </a>
    {props.size == 'big' &&
      (props.article.text.length > 140 ? (
        <a className="article-text" href={props.article.url}>
          {truncate_text(props.article.text, 140)}
        </a>
      ) : (
        <a className="article-text" href={props.article.url}>
          {props.article.text}
        </a>
      ))}
    {props.article.author.fullname && (
      <p className="article-author">
        by
        <a className="article-author-italic" href={props.url_author}>
          {props.article.author.fullname}
        </a>
      </p>
    )}
    <style>
      {`
        .fdn-article-card-desktop-${props.type} {
          display: flex;
          flex-flow: column;
          border: none;
          word-break: break-word;
        }
        .fdn-article-card-desktop-${props.type} .article-image {
          width: 100%;
          height: ${props.height_img_small ? props.height_img_small : `250px`};
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;
          flex-shrink: 0;
        }
        .fdn-article-card-desktop-${props.type}.big .article-image {
          height: ${props.height_img_big ? props.height_img_big : `538px`};
        }
        .fdn-article-card-desktop-${props.type}.big .article-image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .fdn-article-card-desktop-${props.type}.small-article
          .article-image
          .article-image-source {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .fdn-article-card-desktop-${props.type} .article-image:hover {
          ${Vars.HOVER_TYPE.IMAGE}
        }
        .fdn-article-card-desktop-${props.type} .article-title {
          font-family: ${props.font_title};
          font-weight: 600;
          font-size: 20px;
          color: #000;
          line-height: 1.5;
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .fdn-article-card-desktop-${props.type} .article-title:hover,
        .fdn-article-card-desktop-${props.type} .article-text:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
        }
        .fdn-article-card-desktop-${props.type} .article-category {
          font-family: Roboto, sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 1;
          color: #db284e;
          text-transform: uppercase;
          margin: 20px 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-category
          .text-category {
          text-decoration: none;
          color: #db284e;
        }
        .fdn-article-card-desktop-${props.type}
          .article-category
          .text-category:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
          cursor: pointer;
        }
        .fdn-article-card-desktop-${props.type}
          .article-category
          span.article-text-date {
          color: #919eab;
          position: relative;
          margin-left: 45px;
        }
        .fdn-article-card-desktop-${props.type}
          .article-category
          span.article-text-date::before {
          position: absolute;
          content: '';
          width: 20px;
          height: 1px;
          background: #919eab;
          top: 50%;
          left: -30px;
        }
        .fdn-article-card-desktop-${props.type} .article-text {
          font-family: Roboto;
          font-size: 16px;
          line-height: 1.56;
          letter-spacing: 0.5px;
          color: #4a4a4a;
          margin: 10px 0 0 0;
        }
        .fdn-article-card-desktop-${props.type} .article-author {
          font-family: Roboto;
          font-size: 12px;
          color: #919eab;
          margin: 10px 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-author
          .article-author-italic {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 700;
          font-style: italic;
          text-transform: capitalize;
          margin-left: 3px;
          color: #919eab;
          text-decoration: none;
        }
        .fdn-article-card-desktop-${props.type}
          .article-author
          .article-author-italic:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
          cursor: pointer;
        }
      `}
    </style>
  </div>
)

export default TypeTwo
