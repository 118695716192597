import React from 'react'
import Vars from 'Consts/vars'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'

const TypeThree = (props) => (
  <div className={`fdn-article-card-desktop-${props.type}`}>
    <a href={props.article.url} className="article-image">
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ImageFallback
          src={props.article.image}
          alt={props.article.title}
          objectFit="cover"
          layout="fill"
          priority
          quality={80}
          fallbackSrc={Vars.imageBrokenDefault.rectangle}
        />
      </div>
    </a>
    <div className="article-content">
      <p className="numbering">{props.numbering}</p>
      <div className="article-title">
        <a className="title-anchor" href={props.article.url}>
          {props.article.title}
        </a>
        {props.article.author.fullname && (
          <p className="title-p">
            by
            <a href={props.url_author} className="article-author-italic">
              {props.article.author.fullname}
            </a>
          </p>
        )}
      </div>
    </div>
    <style jsx>
      {`
        .fdn-article-card-desktop-${props.type} {
          display: flex;
          flex-flow: column;
          border: none;
          word-break: break-word;
        }
        .fdn-article-card-desktop-${props.type} .article-image {
          width: 100%;
          height: 178px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;
          flex-shrink: 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-image
          .article-image-source {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .fdn-article-card-desktop-${props.type} .article-image:hover {
          ${Vars.HOVER_TYPE.IMAGE}
        }
        .fdn-article-card-desktop-${props.type} .article-content {
          display: grid;
          grid-template-columns: 20% 80%;
          grid-auto-rows: minmax(min-content, max-content);
        }
        .fdn-article-card-desktop-${props.type} .article-content p.numbering {
          font-family: ${props.font_title};
          font-size: 64px;
          font-weight: 400;
          text-align: center;
          padding: 0;
          margin: 10px 0;
          color: #dfe3e8;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title {
          padding: 10px 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          a {
          font-family: ${props.font_title};
          font-weight: 600;
          font-size: 20px;
          color: #000;
          line-height: 1.5;
          margin: 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          a:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .title-p {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 400;
          color: #919eab;
          margin: 10px 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .title-p
          .article-author-italic {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 700;
          font-style: italic;
          text-transform: capitalize;
          margin-left: 3px;
          color: #919eab;
          text-decoration: none;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .title-p
          .article-author-italic:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
          cursor: pointer;
        }
      `}
    </style>
  </div>
)

export default TypeThree
