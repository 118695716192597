import React from 'react'
import Vars from 'Consts/vars'
import { moment_date } from 'Helpers/utils'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'

const TypeTwo = (props) => (
  <div
    className={`fdn-article-card-mobile-${props.type} ${
      props.size === 'main' ? `main` : ``
    }`}
  >
    <a className="article-image-type2" href={props.article.url}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ImageFallback
          src={props.article.image}
          alt={props.article.title}
          objectFit="cover"
          layout="fill"
          priority
          quality={80}
          fallbackSrc={Vars.imageBrokenDefault.rectangle}
        />
      </div>
    </a>
    <div className="article-info">
      <p className="article-category">
        <a href={props.url_category} className="text-category">
          {props.article.category.name}
        </a>
      </p>
      <a className="article-title" href={props.article.url}>
        {props.article.title}
      </a>
      <p className="article-author">
        by{' '}
        <a className="article-author-italic" href={props.url_author}>
          {props.article.author.fullname}
        </a>
        <span>{moment_date({ date: props.article.date })}</span>
      </p>
    </div>
    <style jsx>
      {`
        .fdn-article-card-mobile-${props.type} {
          display: flex;
          flex-flow: row;
          border: none;
          padding: 0 0 10px 0;
        }
        .fdn-article-card-mobile-${props.type}.main {
          display: flex;
          flex-direction: column;
          flex-flow: column;
          border: none;
        }
        .fdn-article-card-mobile-${props.type} .article-info {
          word-break: break-word;
        }
        .fdn-article-card-mobile-${props.type}.main .article-info {
          word-break: break-word;
          padding: 10px 0;
          text-align: center;
        }
        .fdn-article-card-mobile-${props.type} .article-image-type2 {
          width: ${props.size == 'big' ? '100%' : '100px'};
          height: ${props.size == 'big' ? '190px' : '100px'};
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-shrink: 0;
          overflow: hidden;
          margin: 0 8px 0 0;
        }
        .fdn-article-card-mobile-${props.type}.main .article-image-type2 {
          width: 100%;
          height: 170px;
          margin: 0;
        }
        .fdn-article-card-mobile-${props.type} .article-image-type2 div {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-category {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          color: #db284e;
          margin: 0 0 4px 0;
          text-transform: uppercase;
          padding: 0;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-category
          .text-category {
          text-decoration: none;
          color: #db284e;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-category
          .text-category:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
          cursor: pointer;
        }
        .fdn-article-card-mobile-${props.type}.main
          .article-info
          p.article-category {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          color: #db284e;
          margin: 0 0 4px 0;
          text-transform: uppercase;
          padding: 0;
        }
        .fdn-article-card-mobile-${props.type} .article-info a.article-title {
          font-family: ${props.font_title};
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          color: #000;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .fdn-article-card-mobile-${props.type} .article-info p.article-author {
          font-family: Roboto;
          font-weight: 400;
          font-size: 12px;
          color: #919eab;
          margin: 10px 0 0 0;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-author
          .article-author-italic {
          font-family: Roboto;
          font-weight: 500;
          font-size: 12px;
          font-style: italic;
          text-transform: capitalize;
          color: #919eab;
          margin: 10px 0 0 0;
          text-decoration: none;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-author
          .article-author-italic:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
          cursor: pointer;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-author
          span {
          color: #919eab;
          position: relative;
          margin-left: 30px;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-author
          span:last-child::before {
          position: absolute;
          content: '';
          width: 12px;
          height: 1px;
          background: #000;
          top: 50%;
          left: -20px;
        }
        .fdn-article-card-mobile-${props.type}.main
          .article-info
          a.article-title {
          font-family: ${props.font_title};
          font-size: 20px;
          font-weight: 600;
          line-height: 1.5;
          color: #000;
          margin: 10px 0 0 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      `}
    </style>
  </div>
)

export default TypeTwo
