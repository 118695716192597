import React from 'react'
import Vars from 'Consts/vars'
import moment from 'moment'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'

const TypeSeven = (props) => (
  <div className={`article-card-mobile-${props.type}`}>
    <a className="article-image-7" href={props.article.url}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ImageFallback
          src={props.article.image}
          alt={props.article.title}
          layout="fixed"
          objectFit="cover"
          width={100}
          height={100}
          quality={80}
          fallbackSrc={Vars.imageBrokenDefault.square}
        />
      </div>
    </a>
    <div className="article-info">
      <a className="title-anchor" href={props.article.url}>
        {props.article.title}
      </a>
      <p className="title-p">
        {moment(props.article.date).format('DD MMMM YYYY')}
      </p>
    </div>
    <style jsx>
      {`
        .article-card-mobile-${props.type} {
          display: flex;
          flex-flow: row;
          border: none;
          padding: 0 0 10px 0;
        }
        .article-card-mobile-${props.type} .article-info {
          word-break: break-word;
        }
        .article-card-mobile-${props.type} .article-image-7 {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-shrink: 0;
          overflow: hidden;
          margin: 0 8px 0 0;
        }
        .article-card-mobile-${props.type} .article-image-7 img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .article-card-mobile-${props.type} .article-info .title-anchor {
          font-family: ${props.font_title};
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          color: #000;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .article-card-mobile-${props.type} .article-info .title-p {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 400;
          color: #919eab;
          margin: 10px 0 0 0;
        }
      `}
    </style>
  </div>
)

export default TypeSeven
