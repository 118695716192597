import React from 'react'
import Vars from 'Consts/vars'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'

const TypeFour = (props) => (
  <div
    className={`fdn-article-card-mobile-${props.type} ${
      props.size === 'main' ? `main` : ``
    }`}
  >
    <a className="article-image" href={props.article.url}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ImageFallback
          src={props.article.image}
          alt={props.article.title}
          layout="fixed"
          objectFit="cover"
          width={100}
          height={100}
          quality={80}
          fallbackSrc={Vars.imageBrokenDefault.square}
        />
      </div>
    </a>
    <div className="article-info">
      <a className="article-title" href={props.article.url}>
        {props.article.title}
      </a>
      <p className="article-author">
        <a>
          by <span>{props.article.author.fullname}</span>
        </a>
      </p>
    </div>
    <style jsx>
      {`
        .fdn-article-card-mobile-${props.type} {
          display: flex;
          flex-flow: row;
          border: none;
          padding: 0 0 10px 0;
        }
        .fdn-article-card-mobile-${props.type}.main {
          display: flex;
          flex-direction: column;
          flex-flow: column;
          border: none;
        }
        .fdn-article-card-mobile-${props.type} .article-info {
          word-break: break-word;
        }
        .fdn-article-card-mobile-${props.type}.main .article-info {
          text-align: center;
          margin: -40px 15px 0px 15px;
          background: #fff;
          word-break: break-word;
          padding: 10px;
          z-index: 2;
        }
        .fdn-article-card-mobile-${props.type} .article-image {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-shrink: 0;
          overflow: hidden;
          margin: 0 8px 0 0;
        }
        .fdn-article-card-mobile-${props.type}.main .article-image {
          width: 100%;
          height: 170px;
          margin: 0;
        }
        .fdn-article-card-mobile-${props.type} .article-image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .fdn-article-card-mobile-${props.type} .article-info a.article-title {
          font-family: ${props.font_title};
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          color: #000;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .fdn-article-card-mobile-${props.type} .article-info p.article-author {
          font-family: Roboto;
          font-weight: 400;
          font-size: 12px;
          color: #919eab;
          margin: 10px 0 0 0;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-author
          span:first-child {
          font-family: Roboto;
          font-weight: 500;
          font-size: 12px;
          font-style: italic;
          text-transform: capitalize;
          color: #919eab;
          margin: 10px 0 0 0;
        }
        .fdn-article-card-mobile-${props.type}.main
          .article-info
          a.article-title {
          font-family: ${props.font_title};
          font-size: 20px;
          font-weight: 600;
          line-height: 1.5;
          color: #000;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      `}
    </style>
  </div>
)

export default TypeFour
