import React from 'react'
import Vars from 'Consts/vars'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'

const TypeThree = (props) => (
  <div className={`fdn-article-card-mobile-${props.type}`}>
    <a className="article-image" href={props.article.url}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ImageFallback
          src={props.article.image}
          alt={props.article.title}
          layout="fixed"
          objectFit="cover"
          width={100}
          height={100}
          quality={80}
          fallbackSrc={Vars.imageBrokenDefault.square}
        />
      </div>
    </a>
    <div className="article-info">
      <a className="article-title" href={props.article.url}>
        {props.article.title}
      </a>
      <p className="article-author">
        <a href={props.url_author} className="">
          by <span>{props.article.author.fullname}</span>
        </a>
      </p>
      <p className="numbering">{props.numbering}</p>
    </div>
    <style jsx>
      {`
        .fdn-article-card-mobile-${props.type} {
          display: flex;
          flex-flow: row;
          border: none;
          padding: 0 0 10px 0;
        }
        .fdn-article-card-mobile-${props.type} .article-info {
          position: relative;
          word-break: break-word;
        }
        .fdn-article-card-mobile-${props.type} .article-image {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-shrink: 0;
          overflow: hidden;
          margin: 0 8px 0 0;
        }
        .fdn-article-card-mobile-${props.type} .article-image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .fdn-article-card-mobile-${props.type} .article-info a.article-title {
          font-family: ${props.font_title};
          font-size: 16px;
          font-weight: 600;
          line-height: 1.3;
          color: #000;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .fdn-article-card-mobile-${props.type} .article-info p.article-author {
          font-family: Roboto;
          font-weight: 400;
          font-size: 12px;
          color: #919eab;
          margin: 10px 0 0 0;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-author
          a {
          font-family: Roboto;
          font-weight: normal;
          font-size: 12px;
          text-transform: capitalize;
          color: #919eab;
          margin: 10px 0 0 0;
          text-decoration: none;
        }
        .fdn-article-card-mobile-${props.type}
          .article-info
          p.article-author
          a
          span:first-child {
          font-weight: 500;
          font-style: italic;
        }
        .fdn-article-card-mobile-${props.type} .article-info p.numbering {
          position: absolute;
          font-family: ${props.font_title};
          font-size: 64px;
          color: #dfe3e8;
          bottom: -15px;
          z-index: -1;
          margin: 0;
          right: 0;
        }
      `}
    </style>
  </div>
)

export default TypeThree
