import React from 'react'
import Vars from 'Consts/vars'
import moment from 'moment'
// import Image from 'next/image'
import ImageFallback from 'Components/imagefallback'

const TypeSeven = (props) => (
  <div className={`fdn-article-card-desktop-${props.type}`}>
    <div className="article-content">
      <a href={props.article.url} className="article-image">
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        >
          <ImageFallback
            src={props.article.image}
            alt={props.article.title}
            layout="fixed"
            objectFit="cover"
            width={57}
            height={57}
            quality={80}
            fallbackSrc={Vars.imageBrokenDefault.square}
          />
        </div>
      </a>
      <div className="article-title">
        <a className="title-anchor" href={props.article.url}>
          {props.article.title}
        </a>
        {props.article.date && (
          <p className="title-p">
            {moment(props.article.date).format('DD MMMM YYYY')}
          </p>
        )}
      </div>
    </div>
    <style jsx>
      {`
        .fdn-article-card-desktop-${props.type} {
          display: flex;
          flex-flow: column;
          border: none;
          word-break: break-word;
          border-bottom: ${props.border_bottom ? `1px solid #DFE3E8` : ``};
          padding-bottom: 20px;
        }
        .fdn-article-card-desktop-${props.type} .article-content {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-auto-rows: minmax(min-content, max-content);
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-image {
          // width: 100px;
          // height: 100px;
          padding: 5px 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-image
          .article-image-source {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-image:hover {
          ${Vars.HOVER_TYPE.IMAGE}
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title {
          padding-left: 15px;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .title-anchor {
          font-family: ${props.font_title};
          font-weight: 600;
          font-size: 15px;
          color: #000;
          line-height: 1.5;
          margin: 0;
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .title-anchor:hover {
          ${Vars.HOVER_TYPE.TEXT_PINK}
        }
        .fdn-article-card-desktop-${props.type}
          .article-content
          .article-title
          .title-p {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 400;
          color: #919eab;
          margin: 10px 0 0 0;
        }
      `}
    </style>
  </div>
)

export default TypeSeven
